<fullstack-page-layout [registration]="(user$ | async)?.registrations">
  <div sidebar class="font-sans uppercase font-medium">
    <fullstack-sidebar-menu
      [options]="{
        logoUrl: 'assets/img/logo.png',
        logoMinUrl: 'assets/img/logo-min.png'
      }"
      [user]="$any(user$ | async)?.given_name + ' ' + $any(user$ | async)?.family_name"
      [userId]="$any(user$ | async)?.sub"
      (logout)="logout()"
    >
      <sidebar-item
        [label]="'Dashboard'"
        [url]="'./dashboard'"
        *permissions="['dashboard_manager']"
        [icon]="'fa-chart-line'"
      >
        <sidebar-item [label]="'Riepilogo'" [url]="'summary'"></sidebar-item>
        <sidebar-item [label]="'Marketing'" [url]="'marketing'"></sidebar-item>
        <sidebar-item [label]="'Marketing-Lead'" [url]="'marketing-lead'" *permissions="['is_admin']"></sidebar-item>
        <sidebar-item [label]="'Controllo ufficio'" [url]="'office-control'"></sidebar-item>
        <sidebar-item [label]="'Classifica'" [url]="'score'"></sidebar-item>
      </sidebar-item>
      <sidebar-item
        [label]="'Dashboard callcenter'"
        [url]="'./dashboard'"
        *permissions="['dashboard_callcenter_manager']"
        [icon]="'fa-chart-line'"
      >
        <sidebar-item [label]="'Callcenter vendita'" [url]="'callcenter-selling'"> </sidebar-item>
        <sidebar-item [label]="'Callcenter acquisto'" [url]="'callcenter-purchase'"> </sidebar-item>
      </sidebar-item>
      <sidebar-item
        [label]="'Anagrafica'"
        [url]="'./contacts'"
        *permissions="['contact_manager']"
        [icon]="'fa-id-card'"
      ></sidebar-item>
      <sidebar-item
        [label]="'Vendita'"
        [url]="'./project/sale'"
        *permissions="['lead_manager', 'opportunity_manager']"
        [icon]="'fa-money-bill-alt'"
      >
        <sidebar-item [label]="'Lead'" [url]="'lead/list'"></sidebar-item>
        <sidebar-item [label]="'Opportunità'" [url]="'opportunity/list'"></sidebar-item>
        <sidebar-item [label]="'Adeguamento prezzi'" [url]="'price-adjustments'"></sidebar-item>
        <sidebar-item [label]="'Valutazione vendita'" [url]="'selling-outcome'"></sidebar-item>
      </sidebar-item>
      <sidebar-item
        [label]="'Locazione'"
        [url]="'./project/rentout'"
        *permissions="['lead_manager', 'opportunity_manager']"
        [icon]="'fa-money-bill-alt'"
      >
        <sidebar-item [label]="'Lead'" [url]="'lead/list'"></sidebar-item>
        <sidebar-item [label]="'Opportunità'" [url]="'opportunity/list'"></sidebar-item>
        <!-- <sidebar-item [label]="'Adeguamento prezzi'" [url]="'price-adjustments'"></sidebar-item> -->
      </sidebar-item>
      <sidebar-item
        [label]="'Acquisto'"
        [url]="'./project/purchase'"
        *permissions="['lead_manager', 'purchase_manager']"
        [icon]="'fa-money-check-alt'"
      >
        <sidebar-item [label]="'Lead'" [url]="'lead/list'"></sidebar-item>
        <sidebar-item [label]="'Opportunità'" [url]="'opportunity/list'"></sidebar-item>
      </sidebar-item>
      <sidebar-item
        [label]="'Affitto'"
        [url]="'./project/rent'"
        *permissions="['lead_manager', 'purchase_manager']"
        [icon]="'fa-money-check-alt'"
      >
        <sidebar-item [label]="'Lead'" [url]="'lead/list'"></sidebar-item>
        <sidebar-item [label]="'Opportunità'" [url]="'opportunity/list'"></sidebar-item>
      </sidebar-item>

      <sidebar-item [label]="'Immobili'" [url]="'./property'" [icon]="'fa-home'"></sidebar-item>
      <sidebar-item
        [label]="'Appuntamenti'"
        [url]="'./appuntamento'"
        *permissions="['event_manager']"
        [icon]="'fa-calendar-alt'"
      ></sidebar-item>
      <sidebar-item
        [label]="'Contestazioni'"
        [url]="'./dispute/list'"
        *permissions="['dispute_manager']"
        [icon]="'fa fa-balance-scale'"
      ></sidebar-item>
      <sidebar-item
        [label]="'Calendario'"
        [url]="'./calendar'"
        *permissions="['event_manager']"
        [icon]="'fa-calendar-day'"
      ></sidebar-item>
      <sidebar-item
        [label]="'Network'"
        [url]="'./network'"
        *permissions="['network_manager', 'is_back_office']"
        [icon]="'fa-project-diagram'"
      ></sidebar-item>
      <sidebar-item
        [label]="'Utenti'"
        [url]="'./users'"
        *permissions="['user_manager', 'can_read_users_list', 'is_back_office']"
        [icon]="'fa-users'"
      >
        <sidebar-item
          [label]="'Lista'"
          [url]="'list'"
          *permissions="['user_manager', 'can_read_users_list']"
        ></sidebar-item>
      </sidebar-item>
      <sidebar-item [label]="'Segnalazioni'" [url]="''" [icon]="'fa-bullhorn'">
        <sidebar-item
          [label]="'Ristrutturazioni'"
          [url]="'https://crm.facileristrutturare.it:90'"
          [externalLink]="true"
        ></sidebar-item>
        <sidebar-item
          [label]="'Mutui'"
          [url]="'https://crm.facileristrutturare.it'"
          [externalLink]="true"
        ></sidebar-item>
      </sidebar-item>
      <sidebar-item
        [label]="'Impostazioni'"
        [url]="'./settings'"
        *permissions="['settings_manager']"
        [icon]="'fa-cogs'"
      >
        <sidebar-item [label]="'Fonti'" [url]="'sources'"></sidebar-item>
        <sidebar-item [label]="'Documenti'" [url]="'documents'"></sidebar-item>
        <sidebar-item [label]="'Sedi'" [url]="'site'"></sidebar-item>
      </sidebar-item>
      <sidebar-item [label]="'File manager'" [url]="'./file-manager'" [icon]="'fa-folder-open'"> </sidebar-item>
      <sidebar-item [label]="'Beta'" [url]="'./'" *permissions="['is_admin']" [icon]="'fa-flask'">
        <sidebar-item [label]="'Roadmap'" [url]="'roadmap'"></sidebar-item>
      </sidebar-item>
    </fullstack-sidebar-menu>
  </div>
  <div class="mainContent" content>
    <router-outlet></router-outlet>
  </div>
</fullstack-page-layout>

<p-toast position="top-right" key="outcomes" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column align-items-start" style="flex: 1">
      <div class="flex align-items-center gap-2">
        <span class="font-bold text-900"> Hai delle valutazioni da effettuare </span>
      </div>
      <div class="font-medium text-lg my-3 text-900">
        {{ message.summary }}
      </div>
      <p-button size="small" label="vai alla pagina" [routerLink]="'./project/sale/selling-outcome'" />
    </div>
  </ng-template>
</p-toast>
