import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import locale from '@angular/common/locales/it';
import { Component, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { environment } from '@fi/environment/environment';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import * as i18nData from '../assets/i18n/it.json';
import { ApiModule, Configuration } from './client';
import { CoreModule } from './core/core.module';
import { AuthGuard } from './core/guards/auth.guard';
import { HttpErrorInterceptor } from './core/interceptor/http-error.interceptor';
import { SetHeaderInterceptor } from './core/interceptor/set-header.interceptor';
import { ServerEventService } from './core/services/server-event.service';
import { ServicesModule } from './core/services/services.module';
import { AppConfigModule } from './core/app-config/app-config.module';
import { DashboardGlobalShared } from './features/dashboard/dashboard-global-shared.service';
import { FeauturesModule } from './features/features.module';
import { SpinnerInterceptor } from './ui';
import { CacheInterceptor } from './core/interceptor/cache.interceptor';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

registerLocaleData(locale);

@Component({
  selector: 'fullstack-root',
  template: `<router-outlet></router-outlet> `,
})
export class AppComponent {
  constructor(
    private primeNGConfig: PrimeNGConfig,
    private se: ServerEventService,
  ) {
    this.primeNGConfig.setTranslation(i18nData);

    this.se.subToServerEvents$().subscribe();
  }
}
@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent],
    exports: [], imports: [ApiModule.forRoot(() => new Configuration({ basePath: environment.apiGatewayURL })),
        RouterModule,
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        FeauturesModule,
        AppConfigModule.forRoot({
            apiGatewayURL: environment.apiGatewayURL,
            applicationId: environment.applicationId,
            apiPortaleURL: environment.apiPortaleURL,
            redirectUrl: environment.redirectUrl,
            apiProxiedUrl: environment.apiGatewayURL,
        })], providers: [
        AuthGuard,
        MessageService,
        { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: SetHeaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { width: '70%' } },
        { provide: LOCALE_ID, useValue: 'it-IT' },
        DashboardGlobalShared,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
