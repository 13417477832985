import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { IJwtPayload } from '@fi/app/core';
import { AppConfigService } from '@fi/app/core/app-config';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { AuthServices } from '../services/auth.services';

@Injectable()
export class AuthHomeGuard {
  constructor(
    private appConfigService: AppConfigService,
    private authService: AuthServices,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.user().pipe(
      switchMap((user: IJwtPayload) => {
        if (user && user.aud && user.aud == this.appConfigService.getApplicationId()) {
          return of(true);
        }
      }),
      catchError((error) =>
        this.authService.authorize(this.appConfigService.getApplicationId(), this.appConfigService.getRedirectUrl()),
      ),
    );
  }
}
