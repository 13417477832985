import { Component, ViewEncapsulation } from '@angular/core';
import { AuthServices } from '@fi/app/core/services/auth.services';
import { AppConfigService } from '@fi/app/core/app-config';

@Component({
  selector: 'login-page',
  templateUrl: `login-page.component.html`,
  encapsulation: ViewEncapsulation.None,
})
export class LoginPageComponent {
  constructor(
    private authService: AuthServices,
    private appConfigService: AppConfigService,
  ) {}

  login() {
    this.authService
      .authorize(this.appConfigService.getApplicationId(), this.appConfigService.getRedirectUrl())
      .subscribe();
  }
}
