import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private messageService: MessageService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((res: HttpResponse<any>) => {
        if (res.status == HttpStatusCode.ResetContent) {
          location.reload();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        const errorData = error.error;
        const message = errorData?.message;

        if (error.status == HttpStatusCode.Forbidden) {
          this.router.navigate(['/login']);
        }

        if (message) {
          let errorMsg = '';
          if (typeof message === 'string') {
            errorMsg = message;
          } else {
            Object.keys(message).forEach((k) => {
              errorMsg += `${message[k].message}\n`;
            });
          }

          this.messageService.add({
            severity: 'error',
            summary: 'Errore',
            detail: errorMsg,
          });
        }
        return throwError(error);
      }),
    );
  }
}
