import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FiRoles } from '@fi/app/core';
import { environment } from '@fi/environment/environment';
import { EventModal } from '@fi/modals/event-modal/event-modal';
import { AppuntamentoModule } from './appuntamento';
import { CalendarioModule } from './calendario';
import { FeauturesSharedModule } from './features-shared.module';
import { FileManagerModule } from './file-manager';
import { HomeModule } from './home/home.module';
import { LoginModule } from './login/login.module';
import { NetworkModule } from './network/network.module';
import { ProfileModule } from './profile';
import { SiteModule } from './site';
import { UtentiModule } from './utenti';
import { ServicesModule } from '../core/services/services.module';

const DECLARATIONS = [];

@NgModule({
  declarations: DECLARATIONS,
  imports: [
    ServicesModule,
    RouterModule,
    HomeModule,
    LoginModule,
    AppuntamentoModule,
    CalendarioModule.forRoot({
      eventModalClass: EventModal,
      eventsEndpoint: `${environment.apiGatewayURL}/events`,
    }),
    UtentiModule.forRoot(),
    NetworkModule.forRoot({}),
    ProfileModule.forRoot({
      apiGatewayURL: environment.apiGatewayURL,
      applicationId: environment.applicationId,
    }),
    FileManagerModule.forRoot({
      endpoint: `${environment.apiGatewayURL}`,
      roles: FiRoles,
    }),
    FeauturesSharedModule,
    SiteModule.forRoot({
      url: `${environment.apiGatewayURL}`,
      exclude: { bcCode: false, phone: false },
    }),
  ],
  providers: [],
  exports: [...DECLARATIONS],
})
export class FeauturesModule {}
