<div class="content-login h-100 font-sans">
  <div class="login">
    <div class="login__block">
      <h1 class="text-4xl mb-5">Accedi al tuo account</h1>
      <div class="form-group text-center mt-5">
        <button mat-fab extended class="px-5" type="submit" (click)="login()" color="primary">
          Accedi
        </button>
      </div>
      <!-- <div class="form-group flex flex-column">
        <a class="btn btn-link btn-xs" href="login-4.html"
          >Hai dimenticato la password</a
        >
      </div> -->
    </div>
  </div>
  <!-- footer.pug start-->
  <footer class="footer-login" id="footer">© 2024, Facile Immobiliare</footer>
  <!-- footer.pug end-->
</div>
<!-- sidebar.pug start-->
<aside class="sidebar-login" id="sidebar">
  <div class="sidebar-login__body">
    <div class="sidebar-login__header">
      <h1 class="sidebar-login__title">Admin</h1>
      <p class="sidebar-login__subtitle"></p>
    </div>
    <p class="sidebar-login__version">Versione 1.2</p>
    <a class="sidebar-login__logo" href="#"
      ><img src="assets/img/logo.png" alt="logo" /></a
    ><a class="sidebar-login__link" href="#">contatti</a>
  </div>
</aside>
<!-- sidebar.pug end-->
